@import "fields.css";
@import "popup.css";
@import "confirm.css";
@import "animations.css";

@media screen and (min-width: 600px) and (max-width: 800px) {
    :root {
        --fontSize: 20px !important;
    }
    .columnContainer {
        margin-top: 15% !important;
    }
}
@media screen and (max-width: 600px) {
    :root {
        --fontSize: 18px !important; 
    }
    .columnContainer {
        margin-top: 40% !important;
    }
}

.spin {
    animation: spin 2s infinite linear;
}
    @keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}

@font-face {
    font-family: "Barlow";
    src: url("./fonts/BarlowCondensed-Regular.ttf");
}

body {
    background-color: var(--bgColor);
    font-family: "Barlow";
    font-size: var(--fontSize);
    margin: unset;
}

.root {
    box-sizing: unset;
    display: flex;
    justify-content: space-between;
    height: 100vh;
    width: 100vw;
}

.columnContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    margin-top: 7%;
}

.copyright {
    position: fixed;
    bottom: 0.5em;
    right: 0.5em;
    font-size: 0.8em;    
}

line {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    padding: 0.6em;
}
line img {
    width: 3em;
}

.disclaimer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.disclaimer span {
    padding: unset;
    margin: unset;
    width: 100%;
    text-align: center;
    font-size: 1em;
}

#hovers {
    position: absolute;
    left: 35%;
    top: 5%;
    z-index: 9;
    width: 30%;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: center;
    background: transparent;
    max-height: 90vh;
}

.hoverDiv {
    width: 100%;
    height: 3em;
    font-size: 1.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 0.7em;
    color: #FFF;
    margin-bottom: 0.5em;
    text-align: center;
    padding: 0.1em 0.5em;
}
.hoverDiv:hover {
    cursor: pointer;
}

.logo {
    width: var(--logoWidth);
}